body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #487185;/* #607d8b;*/ /*#47426e;  #FF729F*/
}
/*  main blue #247DAB  */
/*------------------- Sticky footer styles start ---------------------- */
html {
  position: relative;
  min-height: 100%;
}

/*------------------- Sticky footer styles   end ----------------------- */

.app {
  margin: 0px;
}

/*---------------------------------------------*/
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #BE82FF;
  /*background-color: #fcfcfa;*/
}
/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
  padding: 60px 15px 0;
}



code {
  font-size: 80%;
}

.bigfont {
  font-size:120%;
}
.email-div {
  /*padding-left: 15px;  
  padding-right: 15px;  */
  color: #607D87;
}
.email-text-div {
  min-height: 30px;
}
.email-body-div {
  min-height: 90px;
}
.bg2 {
  background: #e9ecef;  
}
.text-highlight6 {
  color: #ec69bc;  
  font-weight: bold;
}
.text-highlightPink {
  color: #ff1dae;  
  font-weight: bold;
}
.text-highlight4 {
  color: #1546ff;  
  font-weight: bold;
}
.text-highlight5 {
  color: #1546ff;  
  font-weight: bold;
}
.emailheader {
  color:#607D87;
}
.aemail {
  color:#fff700 !important;
}
.noresize {
  resize: none;
}

.glasspane {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 99999;
  pointer-events: none;  
}
.glasspane2 {
  opacity: 1;
  z-index: 99999;
  pointer-events: none;  
}
/*---------------------------*/
.navbar-nav li a {
  line-height: 40px;
  color: white;
}
.navbar-nav li a.active  {
  font-weight: bold;
	color: yellow;
}

.navbar {
  padding-top: 10px;
  padding-bottom: 0px;
  background-color:#8a2be200;
  color: yellow;
}
.navbar-default {
  background-color: yellow;
}
.navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px;
}
.navbar .navbar-brand {
  padding-left: 40px;
}
.nav-item {
  padding-right: 0.5rem;
  /*padding-left: 0.5rem;*/
}
.main-title-div {
  margin-top: 0rem!important;
  /*margin-bottom: 3rem!important;*/
}
/*---------------------------*/
@media screen and (max-width:768px){
  .navbar-brand
  {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    margin: auto;
  }
  .navbar {
    padding-top: 25px;
    padding-bottom: 25px;
  }    
  .navbar .navbar-brand {
    padding-left: 0px;
  }  
  .main-title-div {
    margin-top: 1.5rem!important;
    margin-bottom: 2rem!important;
  }  
}

.subheading-email {
  border-radius: 10px;
  padding-left: 7px;
  padding-right:7px;
  margin:5px;
}
/*---------------
body:after{
  content: "beta";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #ff8ed0;
  top: 7px;
  right: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  -ms-transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
}
---------------*/